import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import raLogo from '../img/function/residentadvisor.png';

class MainBlocks extends Component {

    render() {
      const socials = this.props.socials;
      const iconsExist = (socials) && (socials.mainUrlFacebook || socials.mainUrlInstagram ||
                        socials.mainUrlSoundcloud || socials.mainUrlResidentAdvisor ||
                        socials.mainUrlYouTube || socials.mainUrlSpotify ||
                        socials.mainVoicemailNumber );
      const iconSize = '2x';

      return (
        <div className="main_socials vh-fix">
          {iconsExist &&
            <div className="row_icons">
              {socials.mainUrlFacebook &&
                <a href={socials.mainUrlFacebook} target="_blank" title="Facebook" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} size={iconSize} />
                </a>
              }
              {socials.mainUrlInstagram &&
                <a href={socials.mainUrlInstagram} target="_blank" title="Instagram" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'instagram']} size={iconSize} />
                </a>
              }
              {socials.mainUrlSoundcloud &&
                <a href={socials.mainUrlSoundcloud} target="_blank" title="Soundcloud" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'soundcloud']} size={iconSize} />
                </a>
              }
              {socials.mainUrlResidentAdvisor &&
                <a href={socials.mainUrlResidentAdvisor} target="_blank" title="ResidentAdvisor" rel="noopener noreferrer">
                  <img className="img_logo_icon img_logo_ra" src={raLogo} alt="ResidentAdvisor" />
                </a>
              }
              {socials.mainUrlYouTube &&
                <a href={socials.mainUrlYouTube} target="_blank" title="YouTube" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'youtube']} size={iconSize} />
                </a>
              }
              {socials.mainUrlSpotify &&
                <a href={socials.mainUrlSpotify} target="_blank" title="Spotify" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'spotify']} size={iconSize} />
                </a>
              }
              {socials.mainVoicemailNumber &&
                <a href={'tel:' + socials.mainVoicemailNumber} target="_blank" title="Voicemail" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fal', 'phone-volume']} size={iconSize} />
                </a>
              }
            </div>
          }
        </div>
      );
    }

}

export default MainBlocks;
