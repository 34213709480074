import React, { Component } from 'react';

import MainLanding from './MainLanding';
import MainBlocks from './MainBlocks';
import MainNavigation from './MainNavigation';
import MainSocials from './MainSocials';

class Main extends Component {

    _mailChimpEmbed(html) {
        return { __html: html };
    }

    render() {
      let className = '';
      if (this.props.blur) className += ' blur';

      const { landingImage, bookingEmail, navUrlRecord, recordDisplayOption,
        navUrlBoutique, boutiqueDisplayOption, mainMailChimpEmbed, socials, ...blocks } = this.props.settings || {};
      const landing = { landingImage, bookingEmail };

      const landingExists = !!landingImage;
      const blocksExists = blocks && Object.keys(blocks).length !== 0;
      const socialsExists = socials && Object.keys(socials).length !== 0;

      // set max height on MainNav if there is no data for other components
      const maxNavStyle = (!landingExists && !blocksExists && !socialsExists) ?
        { height: '90vh' } : {};

      return (
        <div id="view_main" className={className}>
          <main>
            {landingExists && <MainLanding landing={landing} />}
            {blocksExists && <MainBlocks blocks={blocks} />}
            <MainNavigation style={maxNavStyle}
              urlRecord={navUrlRecord} recordDisplayOption={recordDisplayOption}
              urlBoutique={navUrlBoutique} boutiqueDisplayOption={boutiqueDisplayOption}
            />
            {socialsExists && <MainSocials socials={socials} />}
            {(mainMailChimpEmbed && mainMailChimpEmbed.length > 0) &&
              <div className="main_subscribe" dangerouslySetInnerHTML={ this._mailChimpEmbed(mainMailChimpEmbed)} />
            }
          </main>
          <footer>
            COPYRIGHT &copy; CLUBBY BOY {new Date().getFullYear()}
          </footer>
        </div>
      );
    }

}

export default Main;
