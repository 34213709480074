import React, { Component } from 'react';

class Overlay extends Component {

  constructor(props) {
    super(props);

    if (this.props.hidden) this.state = { hidden: true };
    else this.state = { hidden: false };

    // bind handlers
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState({ hidden: true });
    this.props.history.replace('/');
  }

  render() {
    return (
      <div id="overlay_bg"
        onClick={this.handleClick}
        className={this.state.hidden ? 'hidden' : ''}>
      </div>
    );
  }

}

export default Overlay;
