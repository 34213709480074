import axios from 'axios';
import React, { Component } from 'react';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

class MainLandingDates extends Component {

    constructor(props) {
      super(props);

      this.state = {
        dates: []
      }
    }

    componentDidMount() {
      const fetchUrl = `/api/functiondates`;

      axios.get(fetchUrl)
        .then(res => {
          const dates = res.data.dates.map(a => {
            let d = new Date(a.date);
            a.date = `${months[d.getMonth()]} ${d.getDate()}`;

            return a;
          })
          this.setState({ dates });
        });
    }

    render() {
      return (
        <div className="main_dates">
          {(this.state.dates && this.state.dates.length > 0) && this.state.dates.map((d, idx) => (
            <div className="main_date" key={`d${idx}`}>
              {d.link
                ?
                  <a href={d.link} className={d.strikethrough ? 'strike' : ''}><span>{d.date}</span> {d.name}</a>
                :
                  <div className={d.strikethrough ? 'strike' : ''}><span>{d.date}</span> {d.name}</div>
              }
            </div>
          ))}
        </div>
      );
    }

}

export default MainLandingDates;
