import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import bioPhoto from '../img/connection/bio_photo.jpg';
import raLogo from '../img/function/residentadvisor.png';

class Connection extends Component {

  // SETTINGS:
  // biography
  // urlFacebook
  // urlTwitter
  // urlInstagram
  // urlSoundcloudConnect
  // urlResidentAdvisor
  // urlYouTube
  // emailAddress

  bodyMarkup(html) {
    return { __html: html };
  }

  render() {
    const settings = this.props.settings;
    const iconsExist = (settings.urlFacebook || settings.urlTwitter ||
                        settings.urlInstagram || settings.urlSoundcloudConnect ||
                        settings.urlResidentAdvisor || settings.urlYouTube ||
                        settings.emailAddress);

    return (
      <div id="view_connection" className="view view_overlay vh-fix">
        <div id="connection_content">
          <div id="connection_body">
            <div id="connection_pic">
              <img src={bioPhoto} alt="DJ Matpat" />
            </div>
            {settings.biography &&
              <div id="connection_bio" className="body_text" dangerouslySetInnerHTML={ this.bodyMarkup(settings.biography) } />
            }
          </div>
          {iconsExist &&
            <div className="row_icons">
              {settings.urlFacebook &&
                <a href={settings.urlFacebook} target="_blank" title="Facebook" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} size="3x" />
                </a>
              }
              {settings.urlTwitter &&
                <a href={settings.urlTwitter} target="_blank" title="Twitter" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'twitter']} size="3x" />
                </a>
              }
              {settings.urlInstagram &&
                <a href={settings.urlInstagram} target="_blank" title="Instagram" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'instagram']} size="3x" />
                </a>
              }
              {settings.urlSoundcloudConnect &&
                <a href={settings.urlSoundcloudConnect} target="_blank" title="Soundcloud" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'soundcloud']} size="3x" />
                </a>
              }
              {settings.urlResidentAdvisor &&
                <a href={settings.urlResidentAdvisor} target="_blank" title="ResidentAdvisor" rel="noopener noreferrer">
                  <img className="img_logo_icon img_logo_ra" src={raLogo} alt="ResidentAdvisor" />
                </a>
              }
              {settings.urlYouTube &&
                <a href={settings.urlYouTube} target="_blank" title="YouTube" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'youtube']} size="3x" />
                </a>
              }
              {settings.emailAddress &&
                <a href={`mailto:${settings.emailAddress}`} title="Email" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fal', 'envelope']} size="3x" />
                </a>
              }
            </div>
          }
        </div>
      </div>
    );
  }

}

export default Connection;
