import React, { Component } from 'react';

import MainLandingDates from './MainLandingDates';

class MainLanding extends Component {

    render() {
      const { landingImage, bookingEmail } = this.props.landing || '';
      const imageSrc = (landingImage) ? landingImage.secure_url : '';
      const mailTo = (bookingEmail) ? `mailto:${bookingEmail}` : '';

      return (
        <div className="main_landing vh-fix">
          {landingImage &&
            <div className="main_image vh-fix"><img className="vh-fix" src={imageSrc} alt="Clubby Boy" /></div>
          }
          <MainLandingDates />
          {(bookingEmail && bookingEmail.length > 0) && 
            <div className="main_booking">
              <a href={mailTo}><span>BOOKING:</span> {bookingEmail}</a>
            </div>
          }
        </div>
      );
    }

}

export default MainLanding;
