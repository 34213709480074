import axios from 'axios';
import React, { Component } from 'react';
import { withRouter, BrowserRouter as Router, Route } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF, faTwitter, faInstagram, faSpotify,
  faSoundcloud, faMixcloud, faTumblr, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight, faPodcast } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope, faPhoneVolume } from '@fortawesome/pro-light-svg-icons';

import { Boutique, ClubbyFunction, Clubcast, Connection, Main, Record } from './views';
import Overlay from './components/Overlay';

import './App.css';

library.add(faFacebookF, faTwitter, faInstagram, faSpotify, faSoundcloud, faPodcast,
  faMixcloud, faTumblr, faYoutube, faEnvelope, faPhoneVolume, faAngleLeft, faAngleDoubleLeft, faAngleRight, faAngleDoubleRight);

// when going to /:page, blur Home component and fade in Overlay component
const MainView = withRouter(props =>
  <Route render={() => <Main blur={(props.location.pathname === "/") ? false : true} {...props} />} />
);

class App extends Component {

  constructor(props) {
    super(props);

    this.state = {
      boutique: {},
      clubcast: {},
      connection: {},
      record: {},
    }
  }

  componentDidMount() {

    // get data from keystone API route
    axios.get('/api/settings')
      .then(res => {
        const siteSettings = res.data.settings

        const { name, description, urliTunes, urlAndroid, urlSoundcloud,
          urlMixcloud, urlTumblr, voicemailNumber,
          biography, urlFacebook, urlTwitter,
          urlInstagram, urlSoundcloudConnect, urlResidentAdvisor,
          urlYouTube, emailAddress,
          navUrlRecord, recordDisplayOption,
          navUrlBoutique, boutiqueDisplayOption,
          mainUrlFacebook, mainUrlInstagram, mainUrlSoundcloud,
          mainUrlResidentAdvisor, mainUrlYouTube, mainUrlSpotify, mainVoicemailNumber,
            ...settingsMain } = siteSettings;

        // social media links for main page
        settingsMain.socials = {
          mainUrlFacebook,
          mainUrlInstagram,
          mainUrlSoundcloud,
          mainUrlResidentAdvisor,
          mainUrlYouTube,
          mainUrlSpotify,
          mainVoicemailNumber
        }
        settingsMain.navUrlRecord = navUrlRecord;
        settingsMain.recordDisplayOption = recordDisplayOption;
        settingsMain.navUrlBoutique = navUrlBoutique;
        settingsMain.boutiqueDisplayOption = boutiqueDisplayOption;

        this.setState({
          settingsMain,
          boutique: {
            navUrlBoutique,
            boutiqueDisplayOption,
          },
          clubcast: {
            description,
            urliTunes,
            urlAndroid,
            urlSoundcloud,
            urlMixcloud,
            urlTumblr,
            voicemailNumber,
          },
          connection: {
            biography,
            urlFacebook,
            urlTwitter,
            urlInstagram,
            urlSoundcloudConnect,
            urlResidentAdvisor,
            urlYouTube,
            emailAddress,
          },
          record: {
            navUrlRecord,
            recordDisplayOption,
          },
        });
      });
  }

  render() {
    let recordURL = (this.state.record.navUrlRecord) ? this.state.record.navUrlRecord.trim() : '';
    let displayRecordOverlay = !recordURL || this.state.record.recordDisplayOption === 'internal';

    let boutiqueURL = (this.state.boutique.navUrlBoutique) ? this.state.boutique.navUrlBoutique.trim() : '';
    let displayBoutiqueOverlay = !boutiqueURL || this.state.boutique.boutiqueDisplayOption === 'internal';

    return (
      <Router>
        <div className="App">
          <div className="view-container">
            <Route path="/:page" component={Overlay} />
            <Route exact path="/function" component={ClubbyFunction} />
            {displayRecordOverlay &&
              <Route exact path="/record" component={Record} />
            }
            <Route exact path="/clubcast" render={() => <Clubcast settings={this.state.clubcast} />} />
            {displayBoutiqueOverlay &&
              <Route exact path="/boutique" component={Boutique} />
            }
            <Route exact path="/connection" render={() => <Connection settings={this.state.connection} />} />
            <MainView settings={this.state.settingsMain}/>
          </div>
        </div>
      </Router>
    );
  }
}

export default App;
