import axios from 'axios';
import React, { Component } from 'react';

class Boutique extends Component {

  constructor(props) {
    super(props);

    this.state = {
      merch: []
    };
  }

  componentDidMount() {
    // get data from keystone API route
    axios.get('/api/merch')
      .then(res => {
        const merch = res.data.merch.filter(fn => fn.image);

        this.setState({ merch });
      });
  }

  render() {

    let merchItems = this.state.merch.map(item =>
      <div class="merch_item">
        <a href={item.link} target="_blank" rel="noopener noreferrer">
          {item.image && <img alt={item.name} src={item.image} />}
          {item.name && <span>{item.name}</span>}
          {item.price && <span>{item.price}</span>}
        </a>
      </div>
    );

    return (
      <div id="view_boutique" className="view view_overlay vh-fix">
        <div id="boutique_content">
          {this.state.merch.length > 0 &&
            <div id="boutique_items">{merchItems}</div>
          }
        </div>
      </div>
    );
  }

}

export default Boutique;
