import axios from 'axios';
import React, { Component } from 'react';
import Swipeable from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Record extends Component {

  constructor(props) {
    super(props);

    this.arrowActiveDelay = 500; // in ms
    this.state = {
      records: [],
      currentRecord: {},
      currentIdx: -1,
      imageHover: false,
      arrowActive: null,
    };

    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleArrowLeft = this.handleArrowLeft.bind(this);
    this.handleArrowRight = this.handleArrowRight.bind(this);
  }

  componentDidMount() {
    // add keydown event listeners
    document.addEventListener("keydown", this.handleKeyDown);

    // get data from keystone API route
    axios.get('/api/records')
      .then(res => {
        const records = res.data.records;
        this.setState({
          records: records,
          currentIdx: 0,
          currentRecord: records[0],
        });
      });

    this.imageInterval = setInterval(this.imageHoverInterval.bind(this), 3000);
  }

  componentWillUnmount() {
    // remove keydown event listeners
    document.removeEventListener("keydown", this.handleKeyDown);
    clearInterval(this.imageInterval);
  }

  arrowLeft() {
    let curr = this.state.currentIdx;
    if (curr <= 0) curr = 0;
    else curr -= 1;
    this.setState({
      currentIdx: curr,
      currentRecord: this.state.records[curr],
      arrowActive: 'left'
    });

    setTimeout(() => {
      this.setState({
        arrowActive: null
      });
    }, this.arrowActiveDelay);
  }

  arrowRight() {
    let curr = this.state.currentIdx;
    let maxLength = this.state.records.length;
    if (curr >= maxLength-1) curr = maxLength-1;
    else curr += 1;
    this.setState({
      currentIdx: curr,
      currentRecord: this.state.records[curr],
      arrowActive: 'right'
    });

    setTimeout(() => {
      this.setState({
        arrowActive: null
      });
    }, this.arrowActiveDelay);
  }

  arrowIconLeft() {
    if (this.state.arrowActive === 'left') {
      return ['far', 'angle-double-left'];
    } else {
      return ['far', 'angle-left'];
    }
  }

  arrowIconRight() {
    if (this.state.arrowActive === 'right') {
      return ['far', 'angle-double-right'];
    } else {
      return ['far', 'angle-right'];
    }
  }

  handleArrowLeft(event) {
    this.arrowLeft();
    event.preventDefault();
  }

  handleArrowRight(event) {
    this.arrowRight();
    event.preventDefault();
  }

  handleKeyDown(event) {
    switch(event.key) {
      case "ArrowUp":
      case "ArrowLeft":
      case "j":
        this.arrowLeft();
        break;
      case "ArrowDown":
      case "ArrowRight":
      case "k":
        this.arrowRight();
        break;
      default:
        break;
    }

    event.preventDefault();
  }

  imageHoverInterval() {
    this.setState({ imageHover: !this.state.imageHover });
  }

  getImageSrc(record) {
    if (this.state.imageHover === true && record.imageBack && record.imageBack.url) {
      return record.imageBack.url;
    } else {
      return record.imageFront.url;
    }
  }

  bodyMarkup(html) {
    return { __html: html };
  }

  render() {
    let currentRecord = (this.state.records.length > 0) ? this.state.records[this.state.currentIdx] : null;
    let showLeftArrow = this.state.currentIdx > 0;
    let showRightArrow = this.state.currentIdx >= 0 && this.state.currentIdx < this.state.records.length-1;
    let imageTitle = (currentRecord && currentRecord.imageFront) ? currentRecord.name : '';

    let imageLink;
    if (currentRecord !== null && currentRecord.linkImageTo === 'image' && currentRecord.imageFront)
      imageLink = currentRecord.imageFront.url;

    let recordLinks = [];
    if (currentRecord !== null) {

      // set link for image
      if (currentRecord.linkImageTo === 'beatport' && currentRecord.urlBeatport)
        imageLink = currentRecord.urlBeatport;
      else if (currentRecord.linkImageTo === 'itunes' && currentRecord.urliTunes)
        imageLink = currentRecord.urliTunes;
      else if (currentRecord.linkImageTo === 'junodownload' && currentRecord.urlJunoDownload)
        imageLink = currentRecord.urlJunoDownload;
      else if (currentRecord.linkImageTo === 'junovinyl' && currentRecord.urlJunoVinyl)
        imageLink = currentRecord.urlJunoVinyl;
      else if (currentRecord.linkImageTo === 'traxsource' && currentRecord.urlTraxsource)
        imageLink = currentRecord.urlTraxsource;
      else if (currentRecord.LinkImageTo === 'spotify' && currentRecord.urlSpotify)
        imageLink = currentRecord.urlSpotify;
      else if (currentRecord.LinkImageTo === 'youtube' && currentRecord.urlYouTube)
        imageLink = currentRecord.urlYouTube;

      // append to record links
      if (currentRecord.urlBeatport)
        recordLinks.push({ text: 'Beatport', href: currentRecord.urlBeatport });
      if (currentRecord.urliTunes)
        recordLinks.push({ text: 'iTunes', href: currentRecord.urliTunes });
      if (currentRecord.urlJunoDownload)
        recordLinks.push({ text: 'Juno Download', href: currentRecord.urlJunoDownload });
      if (currentRecord.urlJunoVinyl)
        recordLinks.push({ text: 'Juno.co.uk', href: currentRecord.urlJunoVinyl });
      if (currentRecord.urlTraxsource)
        recordLinks.push({ text: 'Traxsource', href: currentRecord.urlTraxsource });
      if (currentRecord.urlSpotify)
        recordLinks.push({ text: 'Spotify', href: currentRecord.urlSpotify });
      if (currentRecord.urlYouTube)
        recordLinks.push({ text: 'YouTube', href: currentRecord.urlYouTube });
    }

    const listLinks = recordLinks.map((link) =>
      <li><a href={link.href} target="_blank" title={link.text} rel="noopener noreferrer">{link.text}</a></li>
    );

    return (
      <div id="view_record" className="view view_overlay vh-fix">
        {showLeftArrow &&
          <div className="arrow arrow_left" title="Previous"
          onClick={this.handleArrowLeft}>
            <FontAwesomeIcon icon={this.arrowIconLeft()} size="2x" />
          </div>
        }
        {showRightArrow &&
          <div className="arrow arrow_right" title="Next"
            onClick={this.handleArrowRight}>
            <FontAwesomeIcon icon={this.arrowIconRight()} size="2x" />
          </div>
        }
        {currentRecord &&
          <Swipeable
            trackMouse
            onSwipedLeft={this.handleArrowLeft}
            onSwipedRight={this.handleArrowRight}
          >
            <div id="record_content" className="vh-fix">
              {currentRecord.imageFront &&
                <a id="record_link" href={imageLink} target="_blank" title={imageTitle} rel="noopener noreferrer">
                  <img id="record_img" alt={imageTitle}
                    src={this.getImageSrc(currentRecord)} />
                </a>
              }
              {currentRecord.releaseInfo &&
                <div dangerouslySetInnerHTML={this.bodyMarkup(currentRecord.releaseInfo)} />
              }
              {listLinks.length > 0 &&
                <div id="record_links">
                  Available on
                  <ul>
                    {listLinks}
                  </ul>
                </div>
              }
              {currentRecord.description &&
                <div dangerouslySetInnerHTML={this.bodyMarkup(currentRecord.description)} />
              }
            </div>
          </Swipeable>
        }
      </div>
    );
  }
}

export default Record;
