import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import clubcastTitle from '../img/clubcast/clubcast_title_sm.gif'

class Clubcast extends Component {

  // SETTINGS:
  // description
  // urliTunes
  // urlAndroid
  // urlSoundcloud
  // urlMixcloud
  // voicemailNumber

  // Get Operating System from UserAgent
  // https://stackoverflow.com/a/38241481
  _getOS() {
    var userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  bodyMarkup() {
    return { __html: this.props.settings.description };
  }

  render() {
    const settings = this.props.settings;
    const iconsExist = (settings.urlSoundcloud || settings.urlMixcloud || settings.voicemailNumber);
    const userAgentOS = this._getOS();
    const urlPodcast = (userAgentOS === 'iOS')
      ? settings.urliTunes
      : settings.urlAndroid;

    return (
      <div id="view_clubcast" className="view view_overlay vh-fix">
        <div id="clubcast_content">
          <div id="clubcast_body" className="vh-fix">
            <img className="clubcast_title" src={clubcastTitle} alt="Clubcast" />
            {settings.description &&
              <div id="clubcast_text" className="body_text" dangerouslySetInnerHTML={ this.bodyMarkup() } />
            }
          </div>
          {iconsExist &&
            <div className="row_icons">
              {settings.urlSoundcloud &&
                <a href={settings.urlSoundcloud} target="_blank" title="Soundcloud" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'soundcloud']} size="3x" />
                </a>
              }
              {settings.urlMixcloud &&
                <a href={settings.urlMixcloud} target="_blank" title="Mixcloud" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'mixcloud']} size="3x" />
                </a>
              }
              {urlPodcast &&
                <a href={urlPodcast} target="_blank" title="Podcast" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['far', 'podcast']} size="3x" />
                </a>
              }
              {settings.voicemailNumber &&
                <a href={'tel:' + settings.voicemailNumber} target="_blank" title="Voicemail" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fal', 'phone-volume']} size="3x" />
                </a>
              }
            </div>
          }
        </div>
      </div>
    );
  }

}

export default Clubcast;
